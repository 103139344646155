<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="size"
       :height="size"
       viewBox="0 0 24 24"
       :aria-labelledby="icon"
       role="presentation">
    <title :id="icon">
      {{ icon }} icon
    </title>
    <g :fill="color">
      <component :is="icon"/>
    </g>
  </svg>
</template>

<script>
  import components from './IconComponents'

  export default {
    name:  'AppIcon',
    components,
    props: {
      icon:  {
        type:     String,
        required: true,
      },
      size:  {
        type:    [Number, String],
        default: 25,
      },
      color: {
        type:    String,
        default: 'currentColor',
      },
    },
  }
</script>
