export default {
  AccountBox:        () => import('icon-system/components/AccountBoxIcon'),
  AspectRatio:       () => import('icon-system/components/AspectRatio'),
  Add:               () => import('icon-system/components/AddIcon'),
  ArrowLeft:         () => import('icon-system/components/ArrowLeftIcon'),
  ArrowRight:        () => import('icon-system/components/ArrowRightIcon'),
  CallMade:          () => import('icon-system/components/CallMadeIcon'),
  CallReceived:      () => import('icon-system/components/CallReceivedIcon'),
  Cancel:            () => import('icon-system/components/CancelIcon'),
  CheckboxOff:       () => import('icon-system/components/CheckboxOffIcon'),
  CheckboxOn:        () => import('icon-system/components/CheckboxOnIcon'),
  Clear:             () => import('icon-system/components/ClearIcon'),
  Close:             () => import('icon-system/components/CloseIcon'),
  DoubleArrow:       () => import('icon-system/components/DoubleArrowIcon'),
  Download:          () => import('icon-system/components/DownloadIcon'),
  Delete:            () => import('icon-system/components/DeleteIcon'),
  Edit:              () => import('icon-system/components/EditIcon'),
  Email:             () => import('icon-system/components/EmailIcon'),
  ExpandLess:        () => import('icon-system/components/ExpandLessIcon'),
  ExpandMore:        () => import('icon-system/components/ExpandMoreIcon'),
  Facebook:          () => import('icon-system/components/FacebookIcon'),
  Favorite:          () => import('icon-system/components/FavoriteIcon'),
  Headset:           () => import('icon-system/components/HeadsetIcon'),
  Hearing:           () => import('icon-system/components/HearingIcon'),
  Home:              () => import('icon-system/components/HomeIcon'),
  Help:              () => import('icon-system/components/HelpIcon'),
  Info:              () => import('icon-system/components/InfoIcon'),
  LinearScale:       () => import('icon-system/components/LinearScaleIcon'),
  Link:              () => import('icon-system/components/LinkIcon'),
  Loop:              () => import('icon-system/components/LoopIcon'),
  Logo:              () => import('icon-system/components/LogoIcon'),
  LogoFull:          () => import('icon-system/components/LogoFullIcon'),
  Loupe:             () => import('icon-system/components/LoupeIcon'),
  BurgerMenu:        () => import('icon-system/components/BurgerMenu'),
  MoreVert:          () => import('icon-system/components/MoreVertIcon'),
  MusicNote:         () => import('icon-system/components/MusicNoteIcon'),
  NoteWhole:         () => import('icon-system/components/NoteWholeIcon'),
  NoteHalf:          () => import('icon-system/components/NoteHalfIcon'),
  NoteQuarter:       () => import('icon-system/components/NoteQuarterIcon'),
  NoteEighth:        () => import('icon-system/components/NoteEighthIcon'),
  NoteSixteenth:     () => import('icon-system/components/NoteSixteenthIcon'),
  Options:           () => import('icon-system/components/OptionsIcon'),
  Pause:             () => import('icon-system/components/PauseIcon'),
  Person:            () => import('icon-system/components/PersonIcon'),
  Place:             () => import('icon-system/components/PlaceIcon'),
  PlayArrow:         () => import('icon-system/components/PlayArrowIcon'),
  RadioOn:           () => import('icon-system/components/RadioOnIcon'),
  RadioOff:          () => import('icon-system/components/RadioOffIcon'),
  Save:              () => import('icon-system/components/SaveIcon'),
  Search:            () => import('icon-system/components/SearchIcon'),
  Send:            () => import('icon-system/components/SendIcon'),
  Settings:          () => import('icon-system/components/SettingsIcon'),
  Share:             () => import('icon-system/components/ShareIcon'),
  Stop:              () => import('icon-system/components/StopIcon'),
  ThumbsUp:          () => import('icon-system/components/ThumbsUpIcon'),
  Twitter:           () => import('icon-system/components/TwitterIcon'),
  UnfoldMore:        () => import('icon-system/components/UnfoldMoreIcon'),
  VolumeDown:        () => import('icon-system/components/VolumeDownIcon'),
  VolumeMute:        () => import('icon-system/components/VolumeMuteIcon'),
  VolumeOff:         () => import('icon-system/components/VolumeOffIcon'),
  VolumeUp:          () => import('icon-system/components/VolumeUpIcon'),
  ZoomOutVertical:   () => import('icon-system/components/ZoomOutVerticalIcon'),
  ZoomInVertical:    () => import('icon-system/components/ZoomInVerticalIcon'),
  ZoomOutHorizontal: () => import('icon-system/components/ZoomOutHorizontalIcon'),
  ZoomInHorizontal:  () => import('icon-system/components/ZoomInHorizontalIcon')
}
